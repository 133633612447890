<template>
  <div class="customers">
    <div class="title_line flex justify-between">
      <div class="inline-block flex w-1/3 justify-between items-center">
        <h1 class="text-left">Locations</h1>
      </div>

      <div class="inline-block flex w-2/3 justify-end button_group">
        <button
            @click="openDeleteModal"
            :disabled="!show_buttons"
            class="flex items-center last_in_row_button square_button square_red_button">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 4C8 2.89543 8.89543 2 10 2H14C15.1046 2 16 2.89543 16 4V6H8V4Z" stroke="#F2374D" stroke-width="2"/>
            <path d="M3 6H21" stroke="#F2374D" stroke-width="2" stroke-linecap="round"/>
            <path d="M6.33 20.1867L5 6H19L17.67 20.1867C17.5736 21.2144 16.711 22 15.6787 22H8.32127C7.28902 22 6.42635 21.2144 6.33 20.1867Z" stroke="#F2374D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10 18L9.5 10" stroke="#F2374D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14 18L14.5 10" stroke="#F2374D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
      </div>
    </div>

    <div class="filters" v-if="locations">
      <FiltersDop
          :min_filter="min_filter"
          :names="location_names"
          :selected_name="selected_name"
          :items_count="locations_count"
          :rows_length="locations.length"
          :rows_count="rows_count"
      ></FiltersDop>
    </div>

    <div class="customers_table fl_table mt-4 px-4">
      <div class="wrap" v-if="locations">
        <!-- table header-->
        <div class="header py-3 px-2 flex text-left justify-between">
          <div class="item flex items-center justify-center w-24px">
            <input v-model="checked_all" type='checkbox'>
          </div>

          <div class="item flex items-center justify-between w-1/3">
            <span class="strong_text">
              Location name
            </span>
            <span class="sort"
                  @click="sortItems('name')"
                  :class="{
                    'rotate': ascending && sort_names.name,
                    'active_arrow': sort_names.name
                  }">
              <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.82406 6.80139C5.42671 7.37935 4.57332 7.37936 4.17597 6.80139L0.576992 1.56653C0.120833 0.903028 0.595854 8.78263e-08 1.40103 1.58217e-07L8.59897 -1.66193e-07C9.40415 -9.58021e-08 9.87917 0.903026 9.42302 1.56653L5.82406 6.80139Z" fill="black"/>
              </svg>
            </span>
          </div>

          <div class="item w-2/3">
            Address
          </div>

          <div class="item delete_column">
          </div>
        </div>

        <!-- table body -->
        <div class="body py-2">
          <div
              v-for="(item, index) in locations"
              :key="index" :class="checked_location[index] ? 'checked_row' : ''"
              class="py-5 px-2 flex text-left justify-between">

            <div class="item flex items-center justify-center w-24px">
              <input
                  :id="'checked-input-' + index"
                  v-model="checked_location[index]"
                  type='checkbox'>
            </div>

            <div class="item flex items-center justify-start w-1/3">
              <div class="green_text pt-1 pl-1">
                {{ item.name }}
              </div>
            </div>

            <div class="item flex items-center justify-start w-2/3">
              {{ item.address }}
            </div>

            <div class="item flex items-center justify-center w-44px">
              <button @click="deleteThisItem(item.id, index)" class="flex items-center justify-center">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 4C8 2.89543 8.89543 2 10 2H14C15.1046 2 16 2.89543 16 4V6H8V4Z" stroke="#F2374D" stroke-width="2"/>
                  <path d="M3 6H21" stroke="#F2374D" stroke-width="2" stroke-linecap="round"/>
                  <path d="M6.33 20.1867L5 6H19L17.67 20.1867C17.5736 21.2144 16.711 22 15.6787 22H8.32127C7.28902 22 6.42635 21.2144 6.33 20.1867Z" stroke="#F2374D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10 18L9.5 10" stroke="#F2374D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M14 18L14.5 10" stroke="#F2374D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <DeleteModal
        :entity="'location'"
        v-on:delete-select="deleteSelect" v-on:delete-close="closeDelete" ref="delete_modal"/>

  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import FiltersDop from '../../components/FiltersDop';
import {infoMessage} from "../../services/messages";
import DeleteModal from "@/components/DeleteModal";

export default {
  name: "Locations",
  props: {
    rows_count: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      min_filter: true,
      sort_names: {
        name: true,
      },
      location_names:[
        {
          name: 'Location name',
          id: 'name'
        },
        {
          name: 'Address',
          id: 'address'
        },
      ],
      selected_name: {name: 'Location name', id: 'name'},
      user: null,
      checked_location: [],
      checked_all: false,
      show_buttons: false,
      show_manage: false,
      delete_ids: [],
      ascending: false,
      locations: [],
      locations_count: null,
      delete_id: null,
    }
  },
  methods: {
    ...mapMutations([]),
    async getUser() {
      this.user = JSON.parse(localStorage.getItem('user')) || null;
    },
    async sortItems(name) {
      const j = this.sort_names;
      this.checked_trailer = [];
      this.checked_all = false;
      Object.keys(j).forEach(sort_name => {
        j[sort_name] = false;
      })
      this.ascending = !this.ascending;
      this.sort_names[name] = true
      await this.getList(name);
    },
    async getList(sortBy = 'name') {
      try {
        const requestData = {
          skip: this.getRowsToShift || 0,
          take: this.getItemsToShow || 5,
          sort: sortBy,
          column: this.getSelectedFilterItem || 'name',
          search: this.getSearchItemsNames || '',
          ascending: this.ascending,
        }

        const response = await this.$http
            .getAuth(`${this.$http.apiUrl()}/locations?skip=${requestData.skip}&take=${requestData.take}&sort-by=${requestData.sort}&column=${requestData.column}&search=${requestData.search}&sort=${requestData.ascending}`);

        this.locations = response?.data?.locations?.data || [];
        this.locations_count = response?.data?.locations?.count || null;
      } catch (e) {
        console.log(e);
      }
    },
    openDeleteModal() {
      this.$refs.delete_modal.show = true;
    },
    async dropChecked() {
      this.checked_location = [];
    },
    async deleteThisItem(id, index) {
      this.delete_id = id;
      await this.dropChecked();
      this.$refs.delete_modal.show = true;
      if (!this.checked_location[index]) {
        document.getElementById('checked-input-' + index).click();
      }
    },
    closeDelete() {
      this.delete_id = null;
    },
    async deleteSelect() {
      let deleted;
      if (this.delete_id) {
        deleted = await this.$http.deleteAuth(`${this.$http.apiUrl()}/locations/${this.delete_id}/delete`);
      } else {
        deleted = await this.$http.postAuth(`${this.$http.apiUrl()}/locations/multiple-deletion`, {ids: this.delete_ids});
      }

      this.delete_ids = [];
      this.checked_trailer = [];
      if (deleted?.data?.err) {
        console.log(deleted?.data?.err);
        this.delete_id = null;
        return infoMessage(
            'Oops. Something went wrong...',
            this.getTopBanner ? 'error open_top_banner' :'error'
        );
      }

      infoMessage(
          this.delete_id ? `Location deleted` : `locations deleted`,
          this.getTopBanner ? 'success open_top_banner' : 'success'
      );
      this.delete_id = null;
      await this.dropChecked();

      await this.getList();
    },
  },
  async mounted() {
    await this.getUser();
    await this.getList();
  },
  computed: {
    ...mapGetters([
      'getItemsToShow',
      'getSelectedFilterItem',
      'getRowsToShift',
      'getSearchItemsNames',
      'getTopBanner',
    ]),
  },
  components: {
    FiltersDop,
    DeleteModal,
  },
  watch: {
    file_items(val) {
      if (val && val.length) {
        this.pushList(val);
      }
    },
    getItemsToShow() {
      this.getList()
    },
    getSelectedFilterItem() {
      this.getList()
    },
    getRowsToShift() {
      this.getList()
    },
    getSearchItemsNames() {
      this.getList()
    },
    checked_location(val) {
      if (val) {
        let true_or_false = false;
        for (let key in this.checked_location) {
          if (this.checked_location[key]) {
            true_or_false = true;
            if (!this.delete_ids.includes(this.locations[key].id)) {
              this.delete_ids.push(this.locations[key].id);
            }
          } else {
            if (this.delete_ids.includes(this.locations[key].id)) {
              for (let x in this.delete_ids) {
                if (this.delete_ids[x] === this.locations[key].id) {
                  this.delete_ids.splice(x, 1);
                  break;
                }
              }
            }
          }
        }
        this.show_buttons = true_or_false;
      }
    },
    checked_all(val) {
      if (val) {
        for (let key in this.locations) {
          this.checked_location[key] = true;
          this.delete_ids.push(this.locations[key].id);
        }
        this.show_buttons = true;
      } else {
        for (let key in this.locations) {
          this.checked_location[key] = false;
        }
        this.delete_ids = [];
        this.show_buttons = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.customers {
  min-height: calc(100vh - (147px + 18px));
}
.item {
  font-size: 14px;
  padding-right: 25px;
}
.actions {
  &:hover, &:active, &:focus{
    .hidden {
      display: block;
    }
    span {
      &>* {
        cursor: pointer;
      }
      &:first-child {
        &>*{
          margin-left: auto;
        }
      }
    }
  }
}
.title_line {
  padding: 30px 0 22px;
  border-bottom: none;
}
.filters {
  width: 95%;
  margin: auto;
}
.button_text {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
}
.in_row_button {
  margin: 0 15px;
  border-radius: 8px;
}
.last_in_row_button {
  margin-left: 15px;
  border-radius: 8px;
}
.square_button {
  padding: 10px;
  width: 44px;
  height: 44px;
  box-sizing: border-box;
}
.square_green_button {
  color: #1FBC2F;
  border: 2px solid #1FBC2F;
}
.square_red_button {
  color: #F2374D;
  border: 2px solid #F2374D;
  svg {
    path {
      stroke: #F2374D;
    }
  }
}
.square_red_button:disabled {
  color: #3d4852;
  border: 2px solid #3d4852;
  svg {
    path {
      stroke: #3d4852;
    }
  }
}
.strong_text {
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #000000 !important;
}
.delete_column {
  width: 49px;
}
.item {
  span {
    margin-left: 5px;
    color: #969A9F;
  }
}
.green_text {
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #1FBC2F !important;
}
.text_black {
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #000000 !important;
}
.span_cut {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: black !important;
}
.checked_row {
  background-color: #FFF1CE;
}
.button_group {
  position: relative;
  .manage_group {
    z-index: 99;
    position: absolute;
    top: 48px;
    right: 0;
    border-radius: 8px;
    box-sizing: border-box;
    border: 0.5px solid #D8C3AF;
    box-shadow: 5px 5px rgba(216, 195, 175, 0.5);
    padding: 10px;
    background-color: #FFFFFF;
    button {
      margin: 10px;
    }
  }
}
.page {
  position: relative;
}
.blocked {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  min-width: 100vw;
  height: 100%;
  width: 100%;
  z-index: 98;
}
.active_arrow {
  svg {
    path {
      fill: #28a745;
    }
  }
}
.icon_div {
  width: 16px;
  svg {
    width: 100%;
  }
}
</style>
